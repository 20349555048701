// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}plant/all`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}plant/export`
// 导出列表
const exportQRCodeURL = `${API_CONFIG.butlerBaseURL}plant/qrCode/export`
// 批量操作
const batchURL = `${API_CONFIG.butlerBaseURL}plant/status`
// 导入
const imporPlantURL = `${API_CONFIG.butlerBaseURL}plant/import`

// 新增/编辑
const updateURL = `${API_CONFIG.butlerBaseURL}plant`
// 查询详情
const queryURL = `${API_CONFIG.butlerBaseURL}plant`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=shopgoods`
// 获取负责人
const getUsernameListURL = `${API_CONFIG.butlerBaseURL}user/search/username`

export {
  getListURL,
  exportListURL,
  exportQRCodeURL,
  batchURL,
  imporPlantURL,
  updateURL,
  queryURL,
  uploadURL,
  getUsernameListURL
}
