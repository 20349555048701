<!--
 * @Description: 绿植管理--导入页面
 * @Author: 小广
 * @Date: 2019-07-02 11:20:52
 * @LastEditors: 小广
 * @LastEditTime: 2019-07-02 14:41:28
 -->

<template>
  <div class="plantManageImport-wrapper">
    <div class="header">
      <v-button text="返回" @click="goback"></v-button>
    </div>
    <import-template :uploadUrl="uploadUrl" :downloadUrl="downloadUrl">
      <div slot="remark">
       <div>
          <p>1、所属项目：必填，30个长度 ；</p>
          <p>2、绿植名称：必填，50个长度；</p>
          <p>3、科目：非必填，30个长度；</p>
          <p>4、属：非必填，30个长度；</p>
          <p>5、种：非必填，30个长度；</p>
          <p>6、习性：非必填，50个长度；</p>
          <p>7、种植时间：非必填，精确到天</p>
          <p>8、种植地点：非必填，50个长度</p>
          <p>9、简介：非必填，最多500字</p>
          <p>请在excel中按以上顺序填写数据</p>
       </div>
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { imporPlantURL } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl: imporPlantURL,
      downloadUrl: 'static/excel/绿植信息导入模板.xls'
    }
  },
  created () {
    this.$setBreadcrumb('导入')
  },
  methods: {
    goback () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .plantManageImport-wrapper {
    text-align: left;
    background: #fff;
  }
  .header {
    position: absolute;
    top: 20px;
    right: 20px;
    :not(:last-child) {
      margin-right: 8px;
    }
  }
</style>
