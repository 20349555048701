var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "plantManageImport-wrapper" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("v-button", {
            attrs: { text: "返回" },
            on: { click: _vm.goback },
          }),
        ],
        1
      ),
      _c(
        "import-template",
        { attrs: { uploadUrl: _vm.uploadUrl, downloadUrl: _vm.downloadUrl } },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _c("div", [
              _c("p", [_vm._v("1、所属项目：必填，30个长度 ；")]),
              _c("p", [_vm._v("2、绿植名称：必填，50个长度；")]),
              _c("p", [_vm._v("3、科目：非必填，30个长度；")]),
              _c("p", [_vm._v("4、属：非必填，30个长度；")]),
              _c("p", [_vm._v("5、种：非必填，30个长度；")]),
              _c("p", [_vm._v("6、习性：非必填，50个长度；")]),
              _c("p", [_vm._v("7、种植时间：非必填，精确到天")]),
              _c("p", [_vm._v("8、种植地点：非必填，50个长度")]),
              _c("p", [_vm._v("9、简介：非必填，最多500字")]),
              _c("p", [_vm._v("请在excel中按以上顺序填写数据")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }